import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import COLORS from '../styles/colors'
import { HLO } from '../types/global'

type HeaderMenuItem = {
  label: string
  href: string
  target?: '_blank' | '_self'
}

export type HeaderMenuProps = {
  selected: string
  showMenuItems?: boolean
  role?: HLO.Role
}

const HeaderMenu = ({
  selected,
  showMenuItems = true,
  role = 'reader',
}: HeaderMenuProps) => {
  let items: HeaderMenuItem[] = []

  if (showMenuItems) {
    if (['reader'].includes(role)) {
      items = [
        { label: 'The Bookshelf', href: '/bookshelf' },
        { label: 'Pillars of Prejudice', href: '/pillars' },
        { label: 'Reader FAQ', href: '/bookshelf/faq' },
        { label: 'About us', href: '/about' },
      ]
    }
    if (['hlo-admin'].includes(role)) {
      items = [
        { label: 'The Bookshelf', href: '/bookshelf' },
        { label: 'Pillars of Prejudice', href: '/pillars' },
        { label: 'Users', href: '/admin/users/readers' },
        { label: 'Readings', href: '/admin/readings' },
        { label: 'Debriefings', href: '/admin/debriefings' },
        {
          label: 'Content Management',
          href: '/admin/contentmanagement/topics',
        },
        { label: 'Organisations', href: '/admin/orgs' },
      ]
    }
    if (['librarian'].includes(role)) {
      items = [
        { label: 'The Bookshelf', href: '/bookshelf' },
        { label: 'Users', href: '/admin/users/readers' },
        { label: 'Readings', href: '/admin/readings' },
        { label: 'Debriefings', href: '/debriefings/' },
      ]
    }
    if (['book'].includes(role)) {
      items = [
        { label: 'Publishing Dashboard', href: '/book' },
        { label: 'Book FAQ', href: '/book/faq' },
        { label: 'Debriefings', href: '/debriefings/' },
        {
          label: 'Training Portal',
          href: 'https://humanlibrary.org/',
          target: '_blank',
        },
      ]
    }
  }

  return (
    <Stack direction="row" spacing="24px">
      {items.map(({ href, label, target }) => {
        return (
          <Link href={href} key={label} passHref>
            <a target={target}>
              <Typography
                sx={{
                  color: selected === href ? COLORS.greyDark : COLORS.grey,
                  cursor: 'pointer',
                  '&:hover': {
                    color: COLORS.greyDark,
                  },
                }}
                variant="menuItem"
              >
                {label}
              </Typography>
            </a>
          </Link>
        )
      })}
    </Stack>
  )
}

export default HeaderMenu
